<template>
<div class="result" ref="result">
   <article ref="article">
      <header><span>{{name}}</span>
         <el-button
            v-show="name&&shearData.name&&built_in"
            size="small"
            style="margin-left: auto;"
            type="primary"
            @click="pasteFn()">{{ $t('AutoDL.DL.Result.agh6sl') }}</el-button>
         <el-button
            v-show="name&&built_in"
            size="small"
            type="primary"
            @click="Uploadingdata = true;uploadClickFn()">{{ $t('AutoDL.DL.Result.bw9ouq') }}</el-button>

      </header>
      <p>{{ $t('MdmHome.BoxMain3') }}{{size |  byteChange}} <span style="margin-left:10px;"> {{ $t('AutoDL.DL.Result.y2s8wh') }}{{file_count}}</span></p>
      <div class="treeBox" :style="`width:${width}px`" v-if="treeIf">
         <el-tree
            lazy
            :props="props"
            ref="tree"
            :load="loadNode"
            node-key="id"
            highlight-current
            :expand-on-click-node="false"
            @node-click="treeClick">
            <span class="custom-tree-node" slot-scope="{ data }">
               <span v-if="data.type == 'treeMore'">
                  <i class="el-icon-more"></i>&nbsp;{{ $t('AutoDL.DL.Result.4szh9f') }}&nbsp;<i class="el-icon-loading" v-show="data.loading"></i>
               </span>
               <span v-else>
                  <i :class="treeIcon(data.type)"></i>
                  &nbsp;{{ data.name }}&nbsp;{{ data.count?`${$t('config')=='zh'?`(共${data.count}个)`:`(A total of ${data.count})`}`:'' }}&nbsp;
                  <i class="el-icon-scissors button" v-show="built_in" @click="shear(data)"></i>
                  <i class="el-icon-edit button" v-show="built_in" @click="putTree(data.id,data.name)"></i>
                  <i class="el-icon-delete button" v-show="built_in" @click="dellTree(data.id)"></i>
               </span>
            </span>
         </el-tree>
         <div class="Uploading">
            <el-dialog
               :visible.sync="Uploadingdata"
               width="410px"
               class="UploadingBox"
               center
               @closed="updataTreee()"
               :close-on-click-modal="false">
               <h3 slot="title" class="UploadingTitle">
                  {{ $t('AutoDL.DL.Result.ldm3cg') }}
               </h3>
               <el-upload
                  class="upload"
                  ref="upload"
                  drag
                  :headers="headers"
                  :data="upData"
                  :before-upload="handelUpload"
                  :action="upUrl"
                  :on-success="upSuccess"
                  :on-error="upError"
                  accept="image/jpeg,image/jpg,image/png,image/bmp"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">{{$t('Uploading.upload1')}}<em>{{$t('Uploading.upload2')}}</em></div>
                  <!-- <div class="el-upload__tip" slot="tip">只能上传zip文件</div> -->
               </el-upload>
            </el-dialog>
         </div>
      </div>
   </article>
   <div @mousedown="borderMousedown" class="resize"></div>
   <div class="rightBox" ref="rightBox" style="width:100%">
   <div v-if="img" class="imgbox">
      <el-image
         :src="img"
         style="max-width:370px"
         :preview-src-list="imgList"></el-image>
   </div>
   <div class="empty" v-else>
      <el-empty :description="$t('MdmHome.BoxMain5') "></el-empty>
   </div>
</div>
   <section>
      <!-- <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start"
      >
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            circle
            @click="lastStep"
         />
      </el-tooltip> -->
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonNext')"
         placement="top-start">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-right"
            circle
            @click="routerPush('TrainingDL')" />
      </el-tooltip>
   </section>
</div>
</template>

<script>
import {
   Tree
} from 'element-ui';
import {
   mapState
} from "vuex";
export default {
   watch: {
      img: function (val) {
         this.imgList = [val]
      },
   },
   computed: {
      ...mapState([
         "task_id_DL",
         "dataset_id_DL",
      ]),
   },
   data() {
      return {
         props: {
            label: 'name',
            children: 'children',
            isLeaf: function (data) {
               //判断type是否为可展开的文件夹
               return data.type == 2 ? false : true
            }
         },
         name: '',
         size: '',
         file_count: '',
         img: '',
         imgList: [],
         Uploadingdata: false,
         upData: {},
         headers: {
            Authorization: `Token ${localStorage.token.split(" ")[1]}`
         },
         upUrl: `${window.apis.SERVER}${window.apis.IMG_DATASET_FILE}`,
         uploadTreeData: '', //选中的树形组件 Data
         treeIf: true,
         shearData: {},
         built_in: true,
         width:""
      }
   },
   methods: {
      borderMousedown(e){
         e.preventDefault();
         document.onmousemove = (e) => {
         this.$refs.result.style.cursor = 'col-resize'
         let articleLeft = this.$refs.article.getBoundingClientRect().left
         let rightBoxWidth = this.$refs.rightBox.clientWidth
         let resultWidth = this.$refs.result.clientWidth
         let maxWidth = resultWidth - 358
         console.log(maxWidth)
         let width = e.x - articleLeft
         if(width > 350 && width < maxWidth){
            this.width = width
         }
         }
         document.onmouseup = () => {
            this.$refs.result.style.cursor = 'auto'
            document.onmousemove = null;
            document.onmouseup = null;
         };
      },
      pasteFn() {
         let clickData = this.$refs.tree.getNode(this.$refs.tree.getCurrentNode().id)
         let folder_id = ''
         if (clickData.level == 1 && clickData.data.type != 2) {
            folder_id = 0
         } else if (clickData.data.type != 2) {
            folder_id = clickData.parent.data.id
         } else if (clickData.data.type == 2) {
            folder_id = clickData.data.id
         }
         let config = {
            id: this.shearData.id,
            folder_id: folder_id
         }
         this.axios.put(window.apis.IMG_DATASET_FILE, config).then(res => {
            if (res.status == 200) {
               if (clickData.level == 1) {
                  this.treeIf = false
                  setTimeout(() => {
                     this.treeIf = true
                  }, 50)
               } else {
                  let node = this.$refs.tree.getNode(folder_id);
                  node.loaded = false;
                  node.expand();
                  this.$refs.tree.remove(this.shearData.id);
               }
               this.shearData = {}
            }
         })
      },
      shear(data) {
         this.shearData = data
      },
      allowDrop(draggingNode, dropNode, type) {
         //判断是否禁止拖入
         if (dropNode.data.type != 2 && dropNode.level == draggingNode.level) {
            return false;
         } else if (dropNode.level == draggingNode.level && type != "inner") {
            return false;
         } else {
            return true;
         }
      },
      putTree(id, name) {
         this.$prompt('', this.$t('zhong-ming-ming'), {
            confirmButtonText: this.$t('zhong-ming-ming'),
            cancelButtonText: this.$t('qu-xiao'),
            inputValue: name,
         }).then(({
            value
         }) => {
            this.axios({
               method: 'put',
               url: `${window.apis.IMG_DATASET_FILE}${id}/`,
               data: {
                  name: value
               }
            }).then(res => {
               if (res.status == 200 || res.status == 201) {
                  let parent = this.$refs.tree.getNode(id)
                  if (parent.level == 1) {
                     this.treeIf = false
                     setTimeout(() => {
                        this.treeIf = true
                     }, 50)
                  } else {
                     let node = this.$refs.tree.getNode(parent.parent.data.id)
                     node.loaded = false;
                     node.expand();
                  }
               }
            })
         }).catch();
      },
      dellTree(id) {
         this.$confirm(this.$t('MdmHome.delConfirm'), this.$t('MdmHome.delTitle'), {
            //   confirmButtonText: '确定',
            //   cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let config = {
               file_ids: [id]
            }
            this.axios({
               method: 'delete',
               url: window.apis.IMG_DATASET_FILE,
               data: config
            }).then(res => {
               if (res.status == 200 || res.status == 201) {
                  let parent = this.$refs.tree.getNode(id)
                  if (parent.level == 1) {
                     this.treeIf = false
                     setTimeout(() => {
                        this.treeIf = true
                     }, 50)
                  } else {
                     let node = this.$refs.tree.getNode(parent.parent.data.id)
                     node.loaded = false;
                     node.expand();
                  }
                  this.$message({
                     message: this.$t('MdmHome.message3'),
                     type: "success",
                  })
               }
            })
         }).catch()
      },
      uploadClickFn() {
         this.uploadTreeData = this.$refs.tree.getCurrentNode()
         if (this.uploadTreeData.type != 2) {
            this.upData.folder_id = this.$refs.tree.getNode(this.uploadTreeData.id).parent.data.id
         } else if (this.uploadTreeData.type == 2) {
            this.upData.folder_id = this.uploadTreeData.id
         }
      },
      updataTreee() {
         this.$refs.upload.clearFiles()
         if (this.upData.folder_id == 0) {
            console.log(123)
            this.treeIf = false
            setTimeout(() => {
               this.treeIf = true
            }, 50)
            return
         }
         let id = this.uploadTreeData.type == 2 ? this.uploadTreeData.id : this.$refs.tree.getNode(this.uploadTreeData.id).parent.data.id
         let node = this.$refs.tree.getNode(id); // 通过节点id找到对应树节点对象
         node.loaded = false;
         node.expand();
      },
      upSuccess() {
         this.Uploadingdata = false
         this.$message({
            message: this.$t('AutoDL.DataSet.tanq0v'),
            type: "success",
         });
      },
      upError(err) {
         this.Uploadingdata = false
         let myError = err.toString(); //转字符串
         myError = myError.replace("Error: ", "") // 去掉前面的" Error: "
         myError = JSON.parse(myError); //转对象
         this.$message({
            message: this.$t('config') == 'zh' ? myError.zh : myError.en || this.$t('AutoDL.DataSet.sf1v2j'),
            type: "warning",
         });
      },
      handelUpload(file) {
         let Node = this.$refs.tree.getNode(this.$refs.tree.getCurrentNode().id)
         if (Node.level == 1 && Node.data.type != 2) {
            this.upData.folder_id = 0
         }
         this.upData.size = file.size;
         this.upData.name = file.name;
         this.upData.dataset_id = this.dataset_id_DL;
      },
      treeIcon(type) {
         let icon = ''
         //判断 tree icon类型
         switch (type) {
            case '1':
               icon = 'el-icon-picture-outline'
               break;
            case '2':
               icon = 'el-icon-folder'
               break;
            case '3':
               icon = 'el-icon-document'
               break;
            default:
               icon = 'el-icon-document'
               break;
         }
         return icon
      },
      loadNode(node, resolve) {
         if (node.level == 0) {
            this.axios(`${window.apis.IMG_DATASET}${this.dataset_id_DL}/`).then(res => {
               this.name = res.data.name
               this.size = res.data.size
               this.file_count = res.data.summary.file_count
               this.built_in = res.data.user_id === 0 ? false : true
               resolve(res.data.root)
               this.$refs["tree"].setCurrentKey(res.data.root[0].id);
            })
         } else {
            this.axios(`${window.apis.IMG_DATASET_FILE}${node.data.id}/`).then(res => {
               let data = res.data.files
               //如果返回条数大于等于20条在数据尾部插入treeMore，随机id初始 page1
               data.length > 19 ? data.push({
                  id: Math.floor(1000000 + Math.random() * 9000000),
                  type: "treeMore",
                  name: '',
                  page: 1,
                  loading: false
               }) : ''
               resolve(data)
            })
         }
      },
      treeClick(data, node) {
         if (data.type == 1) {
            this.axios(`${window.apis.IMG_DATASET_FILE}${data.id}/`).then(res => {
               this.img = res.data.base64
            })
         } else
         if (data.type == "treeMore") {
            data.loading = true
            this.axios(`${window.apis.IMG_DATASET_FILE}${node.parent.data.id}/?page=${data.page+1}`).then(res => {
               let Treedata = res.data.files
               //如果返回条数大于等于20条在数据尾部插入treeMore，随机id初始 page在上一次+1
               Treedata.length > 19 ? Treedata.push({
                  id: Math.floor(1000000 + Math.random() * 9000000),
                  type: "treeMore",
                  name: '',
                  page: data.page + 1,
                  loading: false
               }) : ''
               //insertAfter在节点尾部新增1个节点
               this.$refs.tree.insertAfter(Treedata[0], data.id);
               for (let key = 0; key < Treedata.length - 1; key++) {
                  this.$refs.tree.insertAfter(Treedata[key + 1], Treedata[key].id);
               }
               data.loading = false
               this.$refs.tree.remove(data.id);
            })
         }
      },
      routerPush(name) {
         this.$router.push({
            name: name,
         });
      },
      handleNodeClick(data) {
         console.log(data);
      }
   }
}
</script>

<style lang="scss" scoped>
.result {
   width: 100%;
   height: auto;
   border: 2px solid #ECEFF3;
   margin-top: 40px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   article {
      padding: 30px 0;
      text-align: left;

      header {
         overflow: hidden;
         display: flex;
         justify-content: space-between;
         align-items: center;
         font-size: 18px;
         font-weight: 500;
         color: #333333;
         line-height: 25px;
         padding: 0 20px;
      }

      p {
         font-size: 14px;
         font-weight: 400;
         color: #666666;
         line-height: 22px;
         padding: 0 20px;
      }

      .treeBox {
         min-width: 350px;
         max-width: calc(100vw - 560px);
         max-height: 40vh;
         overflow: auto;

         .custom-tree-node {
            width: 100%;

            .button {
               color: rgba($color: #606266, $alpha: 0);
               margin: 0 5px 0 5px;
            }
         }

         .custom-tree-node:hover {
            .button {
               color: #606266;
            }
         }
      }
   }
   .resize {
      width: 8px;
      background: #ECEFF3;
      cursor: col-resize;
   }
   .imgbox {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .empty {
      width: 100%;
   }

   section {
      position: fixed;
      right: 100px;
      bottom: 50px;
      z-index: 100;

      .Step {
         margin: 0 15px;
         box-shadow: 0px 0px 7px #333333;
      }
   }

}
</style>
